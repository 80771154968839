.society-images-main-div {
  height: auto;
  display: flex;
}
.society-media-main-div {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  height: auto;
}
.top-society-images-heading-div {
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 50px;
  background-color: #5e72e4;
  color: white;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 12px;
  font-weight: 700;
  justify-content: space-between;
}
.top-society-images-heading-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.society-images-and-video-main-div {
  margin: 1rem;
  border: 1px dashed black;
  padding: 1rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.society-images-add-div {
  width: 100px;
  height: 100px;
  border: 1px dashed black;
  display: flex;
  align-items: center;
}
.society-images-add-div input {
  display: none;
}
.society-video-add-div {
  width: 100px;
  height: 100px;
  border: 1px dashed black;
  display: flex;
  align-items: center;
}
.images-and-view-upload-section {
  display: flex;
}
.society-media-main-div {
  /* display: flex;
  justify-content: space-between; */
  padding: 20px;
}

.upload-section {
  flex: 1;
  margin-right: 20px;
}

.upload-section h3 {
  margin-bottom: 10px;
}

.media-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.media-item {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.upload-images-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.upload-images-section input {
  width: 70%;
}
.upload-images-section h5 {
  width: 30%;
  font-size: 10px;
}
.society-images-div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  border: 1px dashed black;
  padding: 1rem;
}
.upload-images-section label {
  display: flex;
  align-items: center;
}
.upload-title-with-icon {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.view-society-media-main-div {
  /* border: 1px solid black; */
  width: 100%;
  height: auto;
  display: flex;
}
.view-society-media-image-content {
  width: 100%;

  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0.8rem;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 1rem;
  border: 1px dashed black;
}
.view-society-media-video-content {
  width: 50%;
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0.8rem;
  /* position: relative;
  top: 0px ;left: 0px; */
}
.view-society-media-image-content img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  aspect-ratio: 2/3;
  border: 1px dashed black;
  margin: 0.5rem;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.view-society-media-video-content video {
  width: 100%;
  height: 300px;
  object-fit: contain;
  aspect-ratio: 2/3;
  margin: 0.5rem;
  border: 1px dashed black;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.view-society-media-center-line {
  border: 1px dashed black;
  height: auto;
}
.handle-add-society-media-button {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}
.handle-add-society-media-button button {
  padding: 10px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #5e72e4;
  color: white;
  font-family: "Montserrat", sans-serif;
}
.hr-society-images-view {
  background-color: black;
}
.upload-icon {
  font-size: 25px;
}

.delete-button-image {
  position: relative;
  top: 32px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 999 !important;
}
.delete-button-video {
  position: relative;
  top: 17px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 999 !important;
}
.no-data-for-image {
  text-align: center;
  width: 100%;
}
.view-society-media-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.view-society-media-container {
  max-height: 400px; /* Adjust this height as needed */
  overflow: auto; /* Enables scroll when content exceeds the height */
}

.view-society-media-table {
  width: 100%;
  border-collapse: collapse;
}

table {
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd !important;
  font-family: "Montserrat", sans-serif;
}

th {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
}

.thumbnail-image {
  width: 100px;
  height: auto;
}

.thumbnail-video {
  width: 150px;
  height: auto;
}

.delete-button {
font-size: 25px;
  
color: #5e72e4;

  cursor: pointer;
}

.no-data {
  text-align: center;
  font-style: italic;
  color: #888;
}
.dialog-top {
  z-index: 9999999 !important;
}
.top-section {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: end;
  font-size: 30px;
}
.modal-images-video-view img ,.full-image, .full-video {
  width: 100%;
  height: 100%;
  
  
}
.close-icon{cursor: pointer;}
.cursor-pointer{cursor: pointer;}