.data-table-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 1.5rem;
  width: 97%;
}

.data-table {
  width: 95%;
  border-collapse: collapse;
  margin: 0 auto;
}

.data-table th,
.data-table td {
  border: 1px solid #dddddd !important;
  padding: 3px;
  font-size: 15px !important;
  text-align: center;
  width: max-content;
}

.data-table th {
  font-size: 20px;
  height: 40px;
}

.data-table th {
  text-align: left;

  text-align: center;
  width: max-content;
  color: #e11d48;
  background-color: transparent;
  font-size: 28px;
  border: none;
  padding: 5px;
}

.pagination button {
  border: none;
  border-radius: 10px;
  color: #fff;
  background-color: #e11d48;
  font-size: 18px;
  padding: 10px;
  font-weight: 600;
}

.pagination span {
  color: black;
  font-size: 22px;
}

.pagination button:hover {
  cursor: pointer;
}

.dlt-btn:hover {
  cursor: pointer;
}

.pagination {
  display: flex;
  gap: 10px;
  align-items: center;
  position: fixed;
  bottom: 15px;
}

.data-table th {
  font-size: 20px;
  height: 40px;
}

.main-form {
  margin: 120px auto;
  height: 300px !important;
}

.main-form {
  margin: 80px auto;
}


.form-container-login {
  background-color: #5e72e4;
  height: 375px;
  color: white;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
}

.form-container-login h2 {
  padding: 10px;
  height: 50px;
  text-align: center;
}

.form-container-login button {
  height: 45px;
}

.data-table th {
  text-align: left;
  text-align: center;
  background-color: rgb(236, 227, 227);
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tr:hover {
  background-color: #dddddd;
}

.entry-type,
.purpose-type,
.house-details,
.adhar-image,
.user-photo {
  max-width: 70px;
}

.delete-button button {
  height: 30px;
  width: max-content;
  border-radius: 5px;
  color: #e11d48;
  background-color: transparent;
  font-size: 28px;
  border: none;
  padding: 5px;
  font-size: 20px;
  font-weight: 450;
}

.s-page:hover {
  text-decoration: underline;
}

.s-page span {
  text-decoration: underline;
}

.logout {
  height: max-content;
  width: 100px;
  background-color: #e11d48;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}

.three_circle_loader {
  margin-top: 3rem;
}

.preview_image {
  margin-left: 1rem;
  border: none;
}

.preview {
  border: none !important;
}

.data_not_avalible {
  font-size: 1.5rem !important;
  padding-top: 2rem;
}

.data_not h1 {
  font-size: 20px;
}

.request_user_heading {
  font-size: 1rem;
  padding: 1rem;
}

.export_btn_data {
  padding: 5px;
  background-color: #e11d48;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.heading-home {
  display: flex;
  width: 100%;
  position: relative;
  /* border: 1px solid black; */
}

.heading-home h2 {
  width: 100%;
  text-align: center;
  margin: 20px 0px;
}

.request_heading {
  /* border: 1px solid black; */
  width: 100%;
  font-size: 20px;
}

.export_btn_div {
  width: max-content;
  /* border: 1px solid black; */
  height: 30px;
  position: absolute;
}

.MuiInputBase-root input {
  height: 15px;
}

.MuiInputBase-root input:focus {
  border: none;
  border-color: #000000;
}

.date-filter {
  display: flex;
  gap: 40px;
  width: max-content;
}

.search-boxes {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  margin-bottom: 10px;
}

.export-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  z-index: 100;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  z-index: 200;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root:first-of-type {
  height: max-content;
}

.error-message {
  height: 40px;
}

@media screen and (orientation: landscape) and (min-device-width: 368px) and (max-device-width: 1370px) {
  .data-table {
    width: 100%;
  }
}

.data-table td {
  font-size: 14px;
}

.pagination button {
  border: none;
  border-radius: 10px;
  color: #fff;
  background-color: #e11d48;
  font-size: 18px;
  padding: 10px;
  font-weight: 600;
}

.pagination span {
  color: black;
  font-size: 22px;
}

.pagination {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}



.form-container-login h2 {
  padding: 10px;
  text-align: center;
  font-size: 32px;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 450;
}

.form-field input {
  width: 100%;

  padding: 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px;
  font-size: 17px;
}

.error-message {
  color: #fff;
  font-size: 15px;
  margin-top: 5px;
}

.submit-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  letter-spacing: 1px;
  margin-top: 5px;
}

.submit-button:hover {
  background-color: white;
  color: #5e72e4;
}

.main-form {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-page {
  margin-top: 5px;
  padding: 5px;
  font-size: 20px;
  font-weight: 450;
}

.s-page:hover {
  text-decoration: underline;
}

.s-page span {
  /* color: #000000; */
  text-decoration: underline;
}

.logout {
  height: max-content;
  width: 100px;
  background-color: #e11d48;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
}

.pagination-1 {
  position: fixed;
  bottom: 10%;
  right: 10%;
}

.css-w7jooe {
  width: 70% !important;
}

.center_image {
  text-align: center;
}

.user_images_div_getData {
  border: 1px solid rgb(230, 229, 229);
  display: flex !important;
  justify-content: flex-start !important;
  margin-left: -1px !important;
  border-radius: 5px;
  flex-wrap: wrap !important;
}

.user_images_div_getData img {
  width: 70px !important;
  margin: 5px;
  height: 70px !important;
  border: 1px solid #e11d48;
  transition: transform 0.3s ease-in-out;
  /* Smooth transition for the zoom effect */
}

.user_images_div_getData_aadharImg {
  border: 1px solid rgb(230, 229, 229);
  display: flex !important;
  justify-content: flex-start !important;
  margin-left: -1px !important;
  border-radius: 5px;
  flex-wrap: wrap !important;
}

.user_images_div_getData_aadharImg img {
  width: auto !important;
  margin: 5px;
  height: 70px !important;
  border: 1px solid #e11d48;
  transition: transform 0.3s ease-in-out;
}

.user_images_div_getData_aadharImg img:hover {
  transform: scale(1.7);
}

.user_images_div_getData img:hover {
  transform: scale(1.7);
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}

.guest_entry_main_div {
  width: 95%;
}

.error_login {
  height: 10px;
  margin-top: 0.3rem;
}

.no_data_occassional {
  width: 100%;
  position: relative;
  left: 55vh;
  text-align: center;
  padding: 1rem;
}

.guest_entry_table {
  background-color: white !important;
}

.col-margin_top {
  position: relative;
  top: 90px;
}

#search-input-label {
  color: #5e72e4;
}

.export-dropdown {
  z-index: 1000;
}

.login_title {
  color: white;
}

.label_title {
  color: white;
}

.button_close_guest {
  margin-top: -1rem;
}

.background_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Ensures image is centered */
  height: 86vh;
  width: 100%;
  /* Ensures full-width coverage */
  transition: background-image 1s ease-in-out, opacity 0.5s ease;
  /* Smooth transition */
  opacity: 0.9;
  /* Slight transparency for a subtle effect */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .background_image {
    background-size: contain;
    /* Adjust size for mobile */
    height: auto;
    /* Allow flexible height */
  }
}

.loading_page_guard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e8e8e8;
}

.togglePasswordVisibilityLoginPage {
  width: 5%;
  position: absolute;
  cursor: pointer;
  top: -117px;
  right: 0px;
}

.eyebuttonLogin {
  color: black;
}

.togglePasswordVisibilityLoginPageMainDiv {
  position: relative;
  top: 0px;
}

.guest-house-details {
  color: rgb(71, 69, 69) !important;
  font-weight: 600;
  font-size: 13px;
}

.guest-house-details-preview {
  color: rgb(71, 69, 69) !important;
  font-weight: 600;
  font-size: 16px;
}

.table-container1 {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
}

.login-form-main {
  display: flex;
  height: 85vh;
  width: 100%;
}

.login-form-left {
  width: 50%;
}

.login-form-right {
  width: 50%;
}